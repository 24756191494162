import React from "react";
import NumberedList from "./NumberedList";
import Text from "./Text";
import "./index.scss";

const Description = ({ title, text, itemNumber, isNumberedList }) => {
  return (
    <div className={`PgEM-PV-D PgEM-PV-D_item${itemNumber}`}>
      <div className="PgEM-PV-D__title">{title}</div>
      {isNumberedList && <NumberedList text={text} />}
      {!isNumberedList && <Text text={text} />}
    </div>
  );
};

export default Description;
