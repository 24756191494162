import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const NumberedList = ({ text }) => (
  <ol className="PgEM-PV-D-NL">
    {text.map((item, i) => (
      <li className="PgEM-PV-D-NL-item" key={i}>
        <div className="PgEM-PV-D-NL__num">
          <span>{i + 1}</span>
        </div>
        <p className="PgEM-PV-D-NL__text">{item}</p>
      </li>
    ))}
  </ol>
);

NumberedList.propTypes = {
  text: PropTypes.array,
};
export default NumberedList;
