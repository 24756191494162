import React from "react";
import Screenshot from "./Screen";
import TrackedLink from "../../Analytics/TrackedLink";
import Container from "../../common/UI/Container";
import "./index.scss";

const Idea = () => {
  return (
    <section className="PgEM-I">
      <Container mode="standard">
        <h2 className="PgEM-I__title">
          Want to get booming and ready-to-launch product ideas?
        </h2>
        <div className="PgEM-I__content">
          <div className="PgEM-I__info">
            <p className="PgEM-I__boldText">
              Analysts from Sellerhook, part of the AMZScout family, will help
              you find potential bestsellers to sell on Amazon. You can avoid
              products that are declining in sales, and{" "}
              <span>get up to 90 percent of sales</span> in new niches, crushing
              competitors.
            </p>
            <p className="PgEM-I__text">
              Order research of emerging products for your exact business model
              and product range with a one-time purchase. All products will
              outperform based on parameters such as profit margin, revenue,
              growth pace, low competition, or any other criteria you have in
              mind.
            </p>
            <TrackedLink
              classes="PgEM-I__link"
              category="main_page"
              action="order your product research"
              path="https://sellerhook.com/?utm_source=site&utm_medium=amzscout&utm_campaign=main_text"
              target
            >
              ORDER YOUR PRODUCT RESEARCH
            </TrackedLink>
          </div>
          <Screenshot />
        </div>
      </Container>
    </section>
  );
};

export default Idea;
