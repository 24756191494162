import React from "react";
import SVG_STARS from "../../../../assets/images/vector/components/RatingOverview/stars.svg";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Post = ({ title, description, i }) => (
  <div className={getTrueClasses("PgEM-RO-P", `PgEM-RO-P_mod${i + 1}`)}>
    <img src={SVG_STARS} alt="*****" className="PgEM-RO-P__stars" />
    <p className="PgEM-RO-P__title">{title}</p>
    <p className="PgEM-RO-P__description">{description}</p>
  </div>
);

export default Post;
