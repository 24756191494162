import React from "react";
import "./index.scss";

const ChromeStoreRating = () => (
  <section className="PgEM-CSR">
    <div className="PgEM-CSR-content">
      <p className="PgEM-CSR-block PgEM-CSR-block_b1">
        <span className="PgEM-CSR-block__title">5M+</span>
        <span className="PgEM-CSR-block__text">profitable products found</span>
      </p>
      <div className="PgEM-CSR__line" />
      <p className="PgEM-CSR-block PgEM-CSR-block_b2">
        <span className="PgEM-CSR-block__title">100+</span>
        <span className="PgEM-CSR-block__text">Amazon coaches use for teaching</span>
      </p>
      <div className="PgEM-CSR__line" />
      <p className="PgEM-CSR-block PgEM-CSR-block_b3">
        <span className="PgEM-CSR-block__title">500K+</span>
        <span className="PgEM-CSR-block__text">sellers have chosen us</span>
      </p>
    </div>
  </section>
);

export default ChromeStoreRating;
