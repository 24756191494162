import React from "react";
import { SCREENSHOT } from "../index.content";
import "./index.scss";

const Screenshot = () => {
  const { PNG, WEBP, PNG_992, WEBP_992, PNG_MOB, WEBP_MOB } = SCREENSHOT;
  return (
    <div className="PgEM-S-S">
      <picture>
        <source type="image/webp" media="(min-width: 1200px)" srcSet={WEBP} />
        <source media="(min-width: 1200px)" srcSet={PNG} />
        <source
          type="image/webp"
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={WEBP_992}
        />
        <source
          media="(min-width: 992px) and (max-width: 1199px)"
          srcSet={PNG_992}
        />
        <source
          type="image/webp"
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={WEBP}
        />
        <source
          media="(min-width: 768px) and (max-width: 991px)"
          srcSet={PNG}
        />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={WEBP_MOB}
        />
        <source media="(max-width: 767px)" srcSet={PNG_MOB} />
        <img src={PNG} className="PgEM-S-S__img" alt="img" loading="lazy" />
      </picture>
    </div>
  );
};

export default Screenshot;
