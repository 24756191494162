import React from "react";
import SVG_check_mark from "../../../../assets/images/vector/components/form/check_mark.svg";

export const CreateTitle = ({ title }) => {
  return (
    <>
      <p className="GCF-FP-T__title">{title}</p>
      <p className="GCF-FP-T__subTitle">
        <span><img src={SVG_check_mark} alt="" />No credit card required</span>
        <span><img src={SVG_check_mark} alt="" />Unlimited-time trial</span>
      </p>
    </>
  );
};
export const DEFAULT_OPTION = {
  en: {
    title: <CreateTitle title="Enter your email to start your FREE TRIAL and receive one product idea in your inbox" />,
    agree: "By entering your email, you agree to receive marketing emails from AMZScout",
    redirectButton: {
      buttonText: "Try AMZScout for Free!",
    },
    form: {
      buttonText: "Try AMZScout for Free!",
      placeholder: "example@gmail.com",
    },
  },
};

