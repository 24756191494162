import PNG_1 from "../../../assets/images/raster/components/SuccessStories/john.png";
import WEBP_1 from "../../../assets/images/raster/components/SuccessStories/john.webp";
import PNG_2 from "../../../assets/images/raster/components/SuccessStories/mario.png";
import WEBP_2 from "../../../assets/images/raster/components/SuccessStories/mario.webp";
import PNG_3 from "../../../assets/images/raster/components/SuccessStories/emily.png";
import WEBP_3 from "../../../assets/images/raster/components/SuccessStories/emily.webp";
import PNG_4 from "../../../assets/images/raster/components/SuccessStories/silvia.png";
import WEBP_4 from "../../../assets/images/raster/components/SuccessStories/silvia.webp";

const POSTS = {
  firstItem: [
    {
      PNG: PNG_1,
      WEBP: WEBP_1,
      name: "John",
      locations: "US",
      text: "“I used other tools before I switched to AMZScout. I really like their seller software, especially the keyword finders and product trackers that give me good references.”"
    },
    {
      PNG: PNG_2,
      WEBP: WEBP_2,
      name: "Mario",
      locations: "UK",
      text: "“Honestly, it’s like you’re trying to find the exit in the dark, and then suddenly you get a flashlight. With AMZScout, quality and productivity go hand in hand. It’s cheap and it's the best method for looking up products and tracking your competitors.”"
    }
  ],
  secondItem: [
    {
      PNG: PNG_3,
      WEBP: WEBP_3,
      name: "Emily",
      locations: "US",
      text: "“Amazon Scout has helped me find profitable products and optimize my listings and PPC campaigns. I’ve seen a huge improvement since I began using this FBA software. If you’re starting selling on Amazon, definitely give them a try.”"
    },
    {
      PNG: PNG_4,
      WEBP: WEBP_4,
      name: "Silvia",
      locations: "ES",
      text: "“I’ve been using AMZScout for a few months now and I’m really impressed. It includes multiple tools to help you perform product research, find keywords, and more. I definitely recommend AMZScout for anyone building an Amazon business.”"
    },
  ]
};
export default POSTS;



