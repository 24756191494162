import React from "react";
import { CreateTitle } from "../common/Forms/FormPage/index.content";

export const URL_LABEL = "r=main_page";

export const CENTER_OPTIONS_FORM = {
  redirectButton: {
    category: "main_page",
  },
};
export const BOTTOM_OPTIONS_FORM = {
  title: <CreateTitle title="Find Profitable Products on Amazon with FREE TRIAL Now!" />,
  customClass: "PgEM-formBottom",
  redirectButton: {
    category: "main_page",
  },
};

export const DEALS_BANNER_CONTAINER_PROPS = {
  dealsUrl: process.env.DEALS_URL,
  dealsSection: process.env.DEALS_SECTION,
  dealsVersion: process.env.DEALS_VERSION,
  label: "Get Holiday Deals!",
  category: "Index",
};