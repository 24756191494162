import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import ARROW_ORANGE from "../../../../assets/images/vector/components/form/arrow_orange.svg";
import SVG_CHECK_MARK from "../../../../assets/images/vector/components/form/check_mark.svg";
import RedirectButton from "./RedirectButton";
import Form from "./Form";
import "./index.scss";

const FormIntro = (props) => {
  const { redirectUrl, option, formOpacity, formCalculatedData } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <div className="PgEM-HI-FI" style={{ opacity: formOpacity }}>
      {isAuth ? (
        <RedirectButton path={redirectUrl} {...option.redirectButton} />
      ) : (
        <div className="PgEM-HI-FI-content">
          <p className="PgEM-HI-FI__title">
            Get started with a free trial and receive one product idea in your
            inbox
          </p>
          <Form {...formCalculatedData} />
          <div className="PgEM-HI-FI-agree">
            <span>
              <img src={SVG_CHECK_MARK} alt="" />
              No credit card required
            </span>
            By entering your email, you agree to receive marketing emails from
            AMZScout
          </div>
          <img className="PgEM-HI-FI__arrow" src={ARROW_ORANGE} alt="" />
        </div>
      )}
    </div>
  );
};

FormIntro.propTypes = {
  option: PropTypes.object,
  redirectUrl: PropTypes.string,
  formCalculatedData: PropTypes.object,
};

export default FormIntro;
