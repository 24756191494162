const OFFER_CONTENT = {
  unauthorized: {
    status: "unauthorized",
    title: "Sign up for free and get:",
    list: [
      "Unlimited-Time Seller’s Toolset Trial",
      "Hot Products and Amazon Trend Reports",
      `Step-by-Step “How to Sell on Amazon” Course`,
    ],
  },
  trial: {
    status: "trial",
    title: "Get for free now:",
    list: [
      "Unlimited-Time Seller’s Toolset Trial",
      "Hot Products and Amazon Trend Reports",
      `Step-by-Step “How to Sell on Amazon” Course`,
    ],
  },
  paid: {
    status: "paid",
    title: "Your full access to:",
    list: [
      "Unlimited-Time Seller’s Toolset Trial",
      "Hot Products and Amazon Trend Reports",
      `Step-by-Step “How to Sell on Amazon” Course`,
    ],
  },
}

export default OFFER_CONTENT
