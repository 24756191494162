import React from "react";
import PropTypes from "prop-types";
import TrackedLink from "../../../../Analytics/TrackedLink";
import "./index.scss";

const RedirectButton = ({ redirectUrl, buttonText, path, category }) => (
  <div className="GCF-FP-RB">
    <TrackedLink
      classes="GCF-FP-RB__link"
      category={category}
      action={buttonText}
      path={path || redirectUrl}
      target
      rel="nofollow"
    >
      {buttonText}
    </TrackedLink>
  </div>
);

RedirectButton.propTypes = {
  redirectUrl: PropTypes.string,
  option: PropTypes.object,
};

export default RedirectButton;
