// import JPC_LUKE from "../../../assets/images/raster/pages/main/Partners/luke.jpg";
import JPC_2X_LUKE from "../../../assets/images/raster/pages/main/Partners/luke@2x.jpg";

// import JPC_JOIE from "../../../assets/images/raster/pages/main/Partners/joie.jpg";
import JPC_2X_JOIE from "../../../assets/images/raster/pages/main/Partners/joie@2x.jpg";

// import JPC_ANDRE from "../../../assets/images/raster/pages/main/Partners/andre.jpg";
import JPC_2X_ANDRE from "../../../assets/images/raster/pages/main/Partners/andre@2x.jpg";

// import JPC_JANSON from "../../../assets/images/raster/pages/main/Partners/janson.jpg";
import JPC_2X_JANSON from "../../../assets/images/raster/pages/main/Partners/janson@2x.jpg";

export const SLIDER_CONFIG = {
  className: "PgEM-P-slider",
  centerMode: true,
  infinite: false,
  arrows: false,
  initialSlide: 0,
  speed: 500,
  touchThreshold: 40,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  dots: true,
};

const LUKE = {
  screen: {
    // JPG: JPC_LUKE,
    JPG_2X: JPC_2X_LUKE,
  },
  path: "/blog/how-to-make-10k-a-month/?r=main_page",
  name: "Luke Wechselberger",
  position: "a six-figure seller & YouTuber",
  resultsBefore: "$5,000/month",
  resultsWithUs: "$10,000/month",
  profit: "+ $5,000",
};

const JOIE = {
  screen: {
    // JPG: JPC_JOIE,
    JPG_2X: JPC_2X_JOIE,
  },
  path: "/blog/is-selling-on-amazon-profitable/?r=main_page",
  name: "Joie Roberts",
  position: "a seven-figure seller & coach",
  resultsBefore: "$0/month",
  resultsWithUs: "$2,000,000/year",
  profit: "+ $2,000,000",
};

const ANDRE = {
  screen: {
    // JPG: JPC_ANDRE,
    JPG_2X: JPC_2X_ANDRE,
  },
  path: "/blog/how-to-build-a-brand/?r=main_page",
  name: "Andre Calzone",
  position: "a private label seller & mentor",
  resultsBefore: "Employee at full-time traditional 9-5 job",
  resultsWithUs: "Owner of 4 profitable businesses",
  profit: "+ 4 online ventures",
};

const JANSON = {
  screen: {
    // JPG: JPC_JANSON,
    JPG_2X: JPC_2X_JANSON,
  },
  path: "/blog/amazon-fba-for-beginners/?r=main_page",
  name: "Janson Smith",
  position: "a seven-figure seller & YouTuber",
  resultsBefore: "Only had $3,500 for investment",
  resultsWithUs: "Made $20,000 profit after selling for 3 months",
  profit: "+ $20,000",
};

export const POSTS = [LUKE, JOIE, ANDRE, JANSON];