import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../helpers";
import BRANDS from "./index.content";
import Container from "../UI/Container";
import "./index.scss";

const Brands = ({ customClass }) => (
  <section className={getTrueClasses("GC-B", customClass)}>
    <Container mode="standard">
      <div className="GC-B-content">
        {BRANDS.map(({ name, icon }) => (
          <div key={name} className="GC-B-logo">
            <img
              className={`GC-B__icon GC-B__icon_${name}`}
              src={icon}
              alt=""
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </Container>
  </section>
);
Brands.propTypes = {
  customClass: PropTypes.string,
};
export default Brands;
