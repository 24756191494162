import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  PAGE_OPTIONS,
  PAGES,
  POPUP_TYPES,
  TEXT_PLEASE_TRY_AGAIN,
} from "../../../helpers/constants";
import PropTypes from "prop-types";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { LayoutContext } from "../../../store/context/layout-context";
import EmailCollectAnalyticsController from "../../../controllers/common/emailCollectAnalyticsController";
import { checkValue, sendEventsCollectEmail } from "../../../helpers";
import { targetStatus } from "../../../helpers/browser";

const FormContainer = (props) => {
  const {
    children,
    page,
    onSuccess,
    redirect = true,
    customRedirectUrl,
    operation = false,
    customType,
    customLabel,
    language = "en",
    showMobileProPopup,
  } = props;
  const [getFormState, setFormState] = useState({
    email: "",
    isFirstTry: true,
    isLoading: false,
    disabled: false,
    error: "",
    isEmpty: true,
    opacity: 0,
  });
  const {
    media: { isTabletOrLess, isNotInitMobile },
    auth: { checkAuth },
  } = useContext(MediaAuthContext);

  const context = useContext(LayoutContext);
  const countRef = useRef(context);
  countRef.current = context;
  const analytics = new EmailCollectAnalyticsController(customLabel, page);
  const pageOptions = PAGE_OPTIONS[PAGES[[page]]];
  const isOperation = operation || !!pageOptions.CLICK_FORM_OPERATION;
  const typeOrOperationValue = isOperation
    ? customType || pageOptions.CLICK_FORM_OPERATION
    : pageOptions.CLICK_FORM_TYPE;
  const software = isOperation ? pageOptions.SOFTWARE : undefined;
  const textPleaseTryAgain =
    TEXT_PLEASE_TRY_AGAIN[language] || TEXT_PLEASE_TRY_AGAIN.en;

  useEffect(() => {
    updateFormState({ opacity: 1 });
  }, [context]);

  const urlRedirect = useMemo(() => {
    const key = isTabletOrLess ? "MOBILE" : "DESKTOP";
    const redirectedUrl = pageOptions.REDIRECTED_URL;
    return typeof redirectedUrl === "object"
      ? redirectedUrl[key]
      : redirectedUrl;
  }, [page, isTabletOrLess]);

  const updateFormState = useCallback((args) => {
    setFormState((prevState) => ({ ...prevState, ...args }));
  }, []);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const isEmpty = !value.length;
    const errorResult = checkValue("email", value, false, language);
    updateFormState({ email: value, isEmpty });
    if (!getFormState.isFirstTry) {
      updateFormState({ error: errorResult, disabled: !!errorResult });
    }
  };
  const defaultSave = (email) => {
    localStorage.setItem("emailCollect", "true");
    localStorage.setItem("collectData", email);
  };

  const handleEmailSubmit = async (e) => {
    const { captcha, token, isInjected, injection } = countRef.current;
    if (e) {
      e.preventDefault();
    }
    if (!isInjected) {
      injection(true);
    }
    const isEmpty = !getFormState.email.length;
    const errorResult = checkValue(
      "email",
      getFormState.email,
      false,
      language
    );
    updateFormState({ error: errorResult, isEmpty });
    if (!errorResult) {
      if (
        typeof window !== "undefined" &&
        !window.location.href.includes("localhost") &&
        !token
      ) {
        if (!captcha.current) {
          updateFormState({ isLoading: true });
          return setTimeout(() => {
            handleEmailSubmit();
          }, 500);
        } else {
          await captcha.current.executeAsync();
          updateFormState({ isLoading: false });
        }
      }
      updateFormState({ isLoading: true });

      sendEventsCollectEmail(
        getFormState.email,
        typeOrOperationValue,
        isOperation,
        software
      )
        .then((response) => {
          if (response.ok) {
            defaultSave(getFormState.email);
            checkAuth();
            updateFormState({ error: "", email: "" });
            if (
              showMobileProPopup &&
              isNotInitMobile &&
              !context.popupState.open
            ) {
              context.openPopup(POPUP_TYPES.MOBILE_PRO);
              analytics.sendEmailSubmitSuccessEvent();
            } else {
              onSuccess && onSuccess();
              analytics.sendEmailSubmitSuccessEvent();
              redirect &&
                window.open(
                  customRedirectUrl ? customRedirectUrl : urlRedirect,
                  targetStatus() ? "_blank" : "_self"
                );
            }
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          analytics.sendEmailValidationEvent(err.message);
          updateFormState({ error: textPleaseTryAgain, disabled: true });
        })
        .finally(() => {
          updateFormState({ isLoading: false, isFirstTry: false });
        });
    } else {
      analytics.sendEmailValidationEvent(getFormState.email.length);
      updateFormState({ disabled: true, isFirstTry: false });
    }
  };

  const handleKeyUp = (e) => {
    const { injection, isInjected } = countRef.current;
    e.keyCode === 13 && !getFormState.disabled && handleEmailSubmit(e);
    e.target.value.length > 0 && !isInjected && injection(true);
  };
  return (
    <>
      {React.cloneElement(children, {
        formOpacity: getFormState.opacity,
        redirectUrl: customRedirectUrl ? customRedirectUrl : urlRedirect,
        formCalculatedData: {
          emailValue: getFormState.email,
          isEmpty: getFormState.isEmpty,
          onError: getFormState.error,
          disabled: getFormState.disabled || getFormState.isLoading,
          onEmailChange: handleEmailChange,
          onKey: handleKeyUp,
          onSubmit: handleEmailSubmit,
        },
      })}
    </>
  );
};

FormContainer.propTypes = {
  children: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
  redirect: PropTypes.bool,
  operation: PropTypes.bool,
  customRedirectUrl: PropTypes.string,
  customType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormContainer;
