import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import ScreenFull from "../../../common/UI/ScreenFull";
import "./index.scss";

const Item = ({ text, href, screen, title }) => (
  <TrackedLink
    classes="PgEM-G-I"
    path={href}
    category="main_page"
    action="article"
    target
  >
    <ScreenFull screen={screen} customClasses="PgEM-G-I__screen" />
    <p className="PgEM-G-I__title">{title}</p>
    <p className="PgEM-G-I__text">{text}</p>
  </TrackedLink>
);

export default Item;
