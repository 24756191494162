import React, { useContext, useEffect, useState } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { SLIDER_CONFIG, POSTS } from "./index.content";
import SimpleSlider from "../../common/UI/SimpleSlider";
import Post from "./Post";
import "./index.scss";

const Partners = () => {
  const [isRendered, setIsRendered] = useState(false);
  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);
  useEffect(() => {
    setIsRendered(true);
    return () => {
      setIsRendered(false);
    };
  }, []);
  return (
    <section className="PgEM-P">
      <h2 className="PgEM-P__title">
        We are Proud of our Partnerships with these Professional Sellers
      </h2>
      <div className="PgEM-P-content">
        {isRendered &&
          (isNotInitMobile ? (
            <SimpleSlider settings={SLIDER_CONFIG}>
              {POSTS.map((data, index) => (
                <Post key={index} index={index} {...data} />
              ))}
            </SimpleSlider>
          ) : (
            POSTS.map((data, index) => (
              <Post key={index} {...data} index={index} />
            ))
          ))}
      </div>
    </section>
  );
};

export default Partners;
