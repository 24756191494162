import React from "react";
import SVG_INCOMPLETE_STARS from "../../../../assets/images/vector/pages/main/RatingOverview/incomplete_stars.svg";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const ChromeStore = () => (
  <div className="PgEM-RO-CS">
    <p className="PgEM-RO-CS-title">
      <span>‘Excellent’</span>
      <img src={SVG_INCOMPLETE_STARS} alt="*****" />
    </p>
    <p className="PgEM-RO-CS-text">
      Over <b>890</b> reviews on{" "}
      <TrackedLink
        classes="PgEM-RO-CS-text__link"
        category="main_page"
        action="Chrome Store"
        path={`${process.env.SCOUT_EXT_PRO}`}
        target
        showMobileProPopup
      >
        Chrome Store
      </TrackedLink>
    </p>
  </div>
);

export default ChromeStore;
