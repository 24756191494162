import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const Text = ({ text }) => (
  <div className="PgEM-PV-D-T">
    {text.map((par, i) => (
      <p key={i} className="PgEM-PV-D-T__par">
        {par}
      </p>
    ))}
  </div>
);
Text.propTypes = {
  text: PropTypes.array,
};
export default Text;
