import React from "react";
import { POINTS_TEXT } from "./index.content";
import SVG_POINTER from "../../../assets/images/vector/pages/main/HomeIntro/pointer.svg";
import Container from "../../common/UI/Container";
import Screenshot from "./Screenshot";
import "./index.scss";

const Support = () => (
  <section className="PgEM-S">
    <Container mode="standard">
      <h2 className="PgEM-S__title">
        Get 24/7 Friendly and Helpful Assistance with AMZScout tools
      </h2>
      <div className="PgEM-S-content">
        <div className="PgEM-S-info">
          <p className="PgEM-S-info__title">
            If you get stuck using any of our tools and services, you don’t have
            to figure it out on your own. We’re here to assist with any of the
            following:
          </p>
          <ul className="PgEM-S-info-list">
            {POINTS_TEXT.map((text, i) => (
              <li key={i} className="PgEM-S-points">
                <img
                  className="PgEM-S-points__pointer"
                  src={SVG_POINTER}
                  alt="-"
                />
                <p className="PgEM-S-points__text">{text}</p>
              </li>
            ))}
          </ul>
        </div>
        <Screenshot />
      </div>
    </Container>
  </section>
);

export default Support;
