import React from "react";
import SVG_LOCATION from "../../../../assets/images/vector/pages/main/SuccessStories/location.svg";
import SVG_STARS from "../../../../assets/images/vector/pages/main/SuccessStories/stars.svg";
import PropTypes from "prop-types";
import "./index.scss";

const Post = ({ PNG, WEBP, name, locations, text }) => (
  <div className="PgEM-SS-P">
    <div className="PgEM-SS-P-title">
      <picture>
        <source type="image/webp" srcSet={WEBP} />
        <img className="PgEM-SS-P__avatar" src={PNG} alt="" loading="lazy" />
      </picture>
      <div className="PgEM-SS-P-profile">
        <p className="PgEM-SS-P__name">{name}</p>
        <p className="PgEM-SS-P-locations">
          <img
            className="PgEM-SS-P-locations__icon"
            src={SVG_LOCATION}
            alt=""
            loading="lazy"
          />
          {locations}
        </p>
      </div>
    </div>
    <img className="PgEM-SS-P-stars" src={SVG_STARS} alt="" loading="lazy" />
    <p className="PgEM-SS-P__text">{text}</p>
  </div>
);
Post.propTypes = {
  PNG: PropTypes.string,
  WEBP: PropTypes.string,
  name: PropTypes.string,
  locations: PropTypes.string,
  text: PropTypes.string,
};
export default Post;
