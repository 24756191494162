import SVG_Disney from "../../../assets/images/vector/components/Brands/disney.svg";
import SVG_Dell from "../../../assets/images/vector/components/Brands/dell.svg";
import SVG_Casio from "../../../assets/images/vector/components/Brands/casio.svg";
import SVG_Redbull from "../../../assets/images/vector/components/Brands/redbull.svg";
import SVG_Alibaba from "../../../assets/images/vector/components/Brands/alibaba.svg";
import SVG_Hp from "../../../assets/images/vector/components/Brands/hp.svg";

const BRANDS = [
  { name: "hp", icon: SVG_Hp },
  { name: "alibaba", icon: SVG_Alibaba },
  { name: "redbull", icon: SVG_Redbull },
  { name: "disney", icon: SVG_Disney },
  { name: "dell", icon: SVG_Dell },
  { name: "casio", icon: SVG_Casio }
];

export default BRANDS;
