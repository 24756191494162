import React from "react";
import PropTypes from "prop-types";
import VideoTestimonials from "../../common/VideoTestimonials";
import "./index.scss";

const Testimonials = ({ setUrlYoutubeModal }) => (
  <section className="PgEM-T">
    <h1 className="PgEM-T__title">Product research tools for Amazon</h1>
    <p className="PgEM-T__subtitle">
      Empowering famous sellers and AMZScout residents
    </p>
    <VideoTestimonials setUrlYoutubeModal={setUrlYoutubeModal} />
  </section>
);
Testimonials.propTypes = {
  setUrlYoutubeModal: PropTypes.func,
};
export default Testimonials;
