import React from "react";
import PropTypes from "prop-types";
import SVG_ARROW from "../../../../assets/images/vector/pages/main/arrow-right.svg";
import TrackedLink from "../../../Analytics/TrackedLink";
import { getTrueClasses } from "../../../../helpers";
import "./index.scss";

const Post = (props) => {
  const {
    index,
    name,
    path,
    position,
    profit,
    resultsBefore,
    resultsWithUs,
    screen: { JPG_2X },
  } = props;

  return (
    <div className={getTrueClasses("PgEM-P-P", `PgEM-P-P_p${index}`)}>
      <div className="PgEM-P-P__screen">
        <img src={JPG_2X} alt="" loading="lazy" />
      </div>
      <div className="PgEM-P-P__info">
        <p className="PgEM-P-P__name">{name}</p>
        <p className="PgEM-P-P__position">{position}</p>
        <p className="PgEM-P-P__label">Results before using AMZScout tools:</p>
        <p className="PgEM-P-P__resultsBefore">{resultsBefore}</p>
        <p className="PgEM-P-P__label">Results with AMZScout tools:</p>
        <p className="PgEM-P-P__resultsWithUs">{resultsWithUs}</p>
        <div className="PgEM-P-P__profit">{profit}</div>
        <TrackedLink
          classes="PgEM-P-P__link"
          category="main_page"
          action="See this case study"
          path={path}
        >
          See this case study
          <img src={SVG_ARROW} alt="->" />
        </TrackedLink>
      </div>
    </div>
  );
};

Post.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  position: PropTypes.string,
  resultsBefore: PropTypes.string,
  resultsWithUs: PropTypes.string,
  profit: PropTypes.string,
};

export default Post;
