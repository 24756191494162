import React from "react";
import PropTypes from "prop-types";
import TrackedLink from "../../../../Analytics/TrackedLink";
import "./index.scss";

const RedirectButton = ({ path, buttonText, category }) => (
  <TrackedLink
    classes="PgEM-HI-FI__RB"
    category={category}
    action={buttonText}
    path={path}
    target
    rel="nofollow"
  >
    {buttonText}
  </TrackedLink>
);

RedirectButton.propTypes = {
  path: PropTypes.string,
  buttonText: PropTypes.string,
  category: PropTypes.string,
};

export default RedirectButton;
