import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";
import PropTypes from "prop-types";
import { DEFAULT_OPTION } from "./index.content";
import { getTrueClasses } from "../../../../helpers";
import Container from "../../UI/Container";
import RedirectButton from "./RedirectButton";
import Form from "./Form";
import "./index.scss";

const FormPage = (props) => {
  const {
    redirectUrl,
    option,
    formCalculatedData,
    language = "en",
  } = props;
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);

  const currentLanguage = Object.keys(DEFAULT_OPTION).includes(language)
    ? language
    : "en";
  const defaultOption = DEFAULT_OPTION[currentLanguage];
  const currentTitle = option?.title || defaultOption.title;
  const currentAgree = option?.agree || defaultOption.agree;
  const currentPlaceholder =
    option?.form?.placeholder || defaultOption.form.placeholder;
  const currentFormButtonText =
    option?.form?.buttonText || defaultOption.form.buttonText;
  const currentRedirectButtonText =
    option?.redirectButton.buttonText ||
    defaultOption.redirectButton.buttonText;

  return (
    <div className={getTrueClasses("GCF-FP", option?.customClass)}>
      <Container mode="standard">
        {isAuth ? (
          <RedirectButton
            buttonText={currentRedirectButtonText}
            path={option?.redirectButton.path}
            redirectUrl={redirectUrl}
            category={option?.redirectButton.category}
          />
        ) : (
          <div className="GCF-FP-content">
            <div className="GCF-FP-T">{currentTitle}</div>
            <div className="GCF-FP-formBlock">
              <Form
                buttonText={currentFormButtonText}
                placeholder={currentPlaceholder}
                formCalculatedData={formCalculatedData}
              />
              <div className="GCF-FP-agree">{currentAgree}</div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

FormPage.propTypes = {
  option: PropTypes.object,
  isAuth: PropTypes.bool,
  redirectUrl: PropTypes.string,
  formCalculatedData: PropTypes.object,
};

export default FormPage;
