import React from "react";
import { COUNTRIES } from "../index.content";
import "./index.scss";

const Countries = () => (
  <div className="PgEM-PV-C">
    {COUNTRIES.map(({ name, flag: { JPG, JPG_mob } }) => (
      <div key={name} className="PgEM-PV-C-C">
        <picture>
          <source media="(min-width: 768px)" srcSet={JPG} />
          <source media="(max-width: 767px)" srcSet={JPG_mob} />
          <img className="PgEM-PV-C-C__flag" src={JPG} alt="" loading="lazy" />
        </picture>
        <p className="PgEM-PV-C-C__name">{name}</p>
      </div>
    ))}
  </div>
);

export default Countries;
