import React from "react";
import "./index.scss";

const Confession = () => (
  <section className="PgEM-C">
    <div className="PgEM-C__content">
      <div className="PgEM-C__decor" />
      <div className="PgEM-C-info">
        <p className="PgEM-C__interest">75%</p>
        <p className="PgEM-C__text">
          of Amazon sellers have admitted to{" "}
          <b>regretting not using product research tools</b> when starting their
          own businesses and searching for their first profitable ideas
        </p>
      </div>
    </div>
  </section>
);

export default Confession;
