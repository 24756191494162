import React from "react";
import SVG_LAPTOP from "../../../assets/images/vector/pages/main/SuccessStories/laptop.svg";
import SVG_UPWARD_MOVEMENT from "../../../assets/images/vector/pages/main/SuccessStories/upward_movement.svg";
import POSTS from "./index.content";
import Post from "./Post";
import "./index.scss";

const SuccessStories = () => {
  const { firstItem, secondItem } = POSTS;
  return (
    <section className="PgEM-SS">
      <div className="PgEM-SS-content">
        <h2 className="PgEM-SS__title">Success stories</h2>
        <p className="PgEM-SS__subtitle">See reviews for AMZScout customers</p>
        <div className="PgEM-SS-list">
          <div className="PgEM-SS-item PgEM-SS-item_one">
            {firstItem.map(({ name, ...data }) => (
              <Post key={name} name={name} {...data} />
            ))}
          </div>
          <div className="PgEM-SS-item PgEM-SS-item_two">
            {secondItem.map((data) => (
              <Post key={data.name} {...data} />
            ))}
          </div>
        </div>
        <img
          className="PgEM-SS__upward"
          src={SVG_UPWARD_MOVEMENT}
          alt=""
          loading="lazy"
        />
        <img
          className="PgEM-SS__laptop"
          src={SVG_LAPTOP}
          alt=""
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default SuccessStories;
