import PNG from "../../../../assets/images/raster/pages/main/Idea/Idea.png";
import PNG_992 from "../../../../assets/images/raster/pages/main/Idea/Idea_992.png";
import PNG_768 from "../../../../assets/images/raster/pages/main/Idea/Idea_768.png";
import PNG_mob from "../../../../assets/images/raster/pages/main/Idea/Idea_mob.png";

import WEBP from "../../../../assets/images/raster/pages/main/Idea/Idea.webp";
import WEBP_992 from "../../../../assets/images/raster/pages/main/Idea/Idea_992.webp";
import WEBP_768 from "../../../../assets/images/raster/pages/main/Idea/Idea_768.webp";
import WEBP_mob from "../../../../assets/images/raster/pages/main/Idea/Idea_mob.webp";

const SCREENSHOT = {
  PNG,
  PNG_992,
  PNG_768,
  PNG_mob,
  WEBP,
  WEBP_992,
  WEBP_768,
  WEBP_mob
};

export default SCREENSHOT;
