import React from "react";
import loadable from "@loadable/component";
import Container from "../../common/UI/Container";
import "./index.scss";

const Row = loadable(() => import("./Row"));

const ProInstall = () => {
  return (
    <section className="PgEM-PI">
      <Container mode="standard">
        <h2 className="PgEM-PI__title">
          Install AMZScout PRO Extension in 3 clicks!
        </h2>
        <p className="PgEM-PI__subtitle">
          Get detailed analytics on products from Amazon in real time
        </p>
        <Row />
      </Container>
    </section>
  );
};

export default ProInstall;
