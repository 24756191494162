import React from "react";
import SVG_ARROW from "../../../../assets/images/vector/pages/main/arrow-right.svg";
import { getTrueClasses } from "../../../../helpers";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Link = ({ mobile }) => (
  <TrackedLink
    classes={getTrueClasses("PgEM-G__Link", mobile && "PgEM-G__Link_mob")}
    category="main_page"
    action="more articles in our blog"
    path="/blog"
    target
  >
    More articles in our blog <img src={SVG_ARROW} alt="->" />
  </TrackedLink>
);

export default Link;
