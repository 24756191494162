import React from "react";
import Container from "../../common/UI/Container";
import Link from "./Link";
import Item from "./Item";
import IDEA_GUIDES_LIST from "./index.content";
import "./index.scss";

const Guides = () => {
  return (
    <section className="PgEM-G">
      <Container mode="standard">
        <h2 className="PgEM-G__title">Learn how to start selling on Amazon</h2>
        <div className="PgEM-G__subtitle">
          Guides and tips for new Amazon sellers
          <Link />
        </div>
        <div className="PgEM-G-list">
          {IDEA_GUIDES_LIST.map((arg, i) => (
            <Item key={i} {...arg} />
          ))}
        </div>
        <Link mobile />
      </Container>
    </section>
  );
};

export default Guides;
