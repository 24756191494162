import React from "react";
import SCREENSHOT from "./index.content";
import "./index.scss";

const Screen = () => {
  const { PNG, PNG_992, PNG_768, PNG_mob, WEBP, WEBP_992, WEBP_768, WEBP_mob } =
    SCREENSHOT;
  return (
    <picture>
      <source type="image/webp" media="(min-width: 1200px)" srcSet={WEBP} />
      <source media="(min-width: 1200px)" srcSet={PNG} />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={WEBP_992}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={PNG_992}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={WEBP_768}
      />
      <source
        media="(min-width: 768px) and (max-width: 991px)"
        srcSet={PNG_768}
      />
      <source type="image/webp" media="(max-width: 767px)" srcSet={WEBP_mob} />
      <source media="(max-width: 767px)" srcSet={PNG_mob} />
      <img className="PgEM-I-S" src={PNG} alt="" loading="lazy" />
    </picture>
  );
};

export default Screen;
