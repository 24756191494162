import PNG_HELP_2X from "../../../assets/images/raster/pages/main/Support/help@2x.png";
import WEBP_HELP_2X from "../../../assets/images/raster/pages/main/Support/help@2x.webp";
import PNG_HELP_992_2X from "../../../assets/images/raster/pages/main/Support/help@992_2x.png";
import WEBP_HELP_992_2X from "../../../assets/images/raster/pages/main/Support/help@992_2x.webp";
import PNG_HELP_MOB_2X from "../../../assets/images/raster/pages/main/Support/help@mob2x.png";
import WEBP_HELP_MOB_2X from "../../../assets/images/raster/pages/main/Support/help@mob2x.webp";

export const SCREENSHOT = {
  PNG: PNG_HELP_2X,
  WEBP: WEBP_HELP_2X,
  PNG_992: PNG_HELP_992_2X,
  WEBP_992: WEBP_HELP_992_2X,
  PNG_MOB: PNG_HELP_MOB_2X,
  WEBP_MOB: WEBP_HELP_MOB_2X
};

export const POINTS_TEXT = [
  "Technical problems arising during registration, installation, and use of AMZScout tools",
  "Practical questions regarding the content and interpretation of data obtained during the research process using our tools",
];

