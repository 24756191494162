import React from "react";
import PropTypes from "prop-types";
import { getTrueClasses } from "../../../../../helpers";
import "./index.scss";

const Form = ({ formCalculatedData, buttonText, placeholder }) => {
  const {
    emailValue,
    isEmpty,
    disabled,
    onEmailChange,
    onError,
    onKey,
    onSubmit,
  } = formCalculatedData;
  return (
    <>
      <form
        className={getTrueClasses(
          "GCF-FP-F",
          !isEmpty && !onError && "GCF-FP-F_typing",
          onError && "GCF-FP-F_alert"
        )}
      >
        <input
          className="GCF-FP-F__input"
          type="text"
          placeholder={placeholder}
          value={emailValue}
          onChange={onEmailChange}
          onKeyDown={onKey}
        />
        <button
          type="button"
          className="GCF-FP-F__button"
          onClick={onSubmit}
          disabled={disabled}
        >
          {buttonText}
        </button>
      </form>
      {onError && <label className="GCF-FP-F__label">{onError}</label>}
    </>
  );
};

Form.propTypes = {
  formCalculatedData: PropTypes.object,
  option: PropTypes.object,
};

export default Form;
